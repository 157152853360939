import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/HomeView.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: HomeView,
        },
        {
            path: "/login",
            name: "login",
            component: () => import("./views/AuthenticationView.vue"),
        },
        {
            path: "/register",
            name: "register",
            component: () => import("./views/AuthenticationView.vue"),
        },
        {
            path: "/settings",
            name: "settings",
            component: () => import("./views/SettingsView.vue"),
        },
        {
            path: "/shoe/:slug",
            name: "shoe",
            props: true,
            component: () => import("./views/ProductView.vue"),
        },
        {
            path: "/search",
            name: "search",
            props: route => ({ query: route.query.query }),
            component: () => import("./views/SearchView.vue"),
        },
        {
            path: "/export",
            name: "bulk-export",
            component: () => import("./views/BulkExportView.vue"),
        },
        {
            path: "/orders",
            name: "orders",
            props: route => (
                {
                    page: Number.parseInt(route.query.page, 10) || undefined,
                    all_page: Number.parseInt(route.query.all_page, 10) || undefined,
                }
            ),
            component: () => import("./views/OrdersView.vue"),
        },
        {
            path: "/order/:id",
            name: "order",
            props: true,
            component: () => import("./views/OrderView.vue"),
        },
        {
            path: "/forgot-password",
            name: "forgot-password",
            component: () => import("./views/ForgotPasswordView.vue"),
        },
        {
            path: "/reset-password",
            name: "reset-password",
            props: route => ({ token: route.query.token }),
            component: () => import("./views/ResetPasswordView.vue"),
        }
    ],
});

export default router;