<script>
import { IconShoppingCart, IconShoppingCartX, IconCircleCheckFilled, IconMoodEmpty, IconTrashX, IconPlus, IconMinus, IconPackageImport } from '@tabler/icons-vue';
import axios from "axios";
import CartItem from "./CartItem.vue";
import BulkImportComponent from "./BulkImportComponent.vue";

export default {
  name: "CartComponent",
  components: {
    BulkImportComponent,
    CartItem,
    IconShoppingCart,
    IconShoppingCartX,
    IconCircleCheckFilled,
    IconMoodEmpty,
    IconTrashX,
    IconPlus,
    IconMinus,
    IconPackageImport
  },
  data() {
    return {
      cartItems: {},
      canUseBulkImport: false,
      checkoutCheckmarkDisabled: true,
      errorMessage: null,
    }
  },
  computed: {
    cartTotal: function () {
      let total = 0;

      for (let item in this.cartItems) {
        total += this.cartItems[item].offer * this.cartItems[item].quantity;
      }

      return total;
    },
    cartQuantity: function () {
      let total = 0;

      for (let item in this.cartItems) {
        total += this.cartItems[item].quantity;
      }

      return total;
    }
  },
  methods: {
    addToCart: function (variantId, isCartPage, event) {
      let button = null;
      if (event !== undefined) {
        button = event.target;
      }

      let doLoading = isCartPage === true && button !== undefined;

      if(doLoading) {
        button.classList.add('btn-loading');
      }

      axios.post(import.meta.env.VITE_BASE_API + '/api/v2/cart/add', {
        variant_id: variantId,
      }).then(response => {
        if (response.data.error) {
          console.log(response.data.error);
          return;
        }

        this.cartItems = response.data.cart;
        this.canUseBulkImport = response.data.can_use_bulk_import;

        if(isCartPage !== true) {
          this.emitter.emit('add-to-cart-response', {
            success: response.data.success
          });
        }

        if(doLoading) {
          button.classList.remove('btn-loading');
        }
      }).catch(error => {
        console.log(error);
        if(isCartPage !== true) {
          this.emitter.emit('add-to-cart-response', {
            success: false
          });
        }

        if(doLoading) {
          button.classList.remove('btn-loading');
        }
      })
    },
    removeFromCart: function (variantId, decrement, event) {
      let button = event.target;

      button.classList.add('btn-loading');

      axios.post(import.meta.env.VITE_BASE_API + '/api/v2/cart/remove', {
        variant_id: variantId,
        decrement: decrement
      }).then(response => {
        if (response.data.error) {
          console.log(response.data.error);
          return;
        }

        this.cartItems = response.data.cart;
        this.canUseBulkImport = response.data.can_use_bulk_import;
        button.classList.remove('btn-loading');

        return response.data.success;
      }).catch(error => {
        console.log(error);

        button.classList.remove('btn-loading');
        return false;
      })
    },
    getCartItems: function () {
      axios.get(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/cart/')
          .then(response => {
            if (response.data.error) {
              console.log(response.data.error);
              return;
            }

            this.cartItems = response.data.cart;
            this.canUseBulkImport = response.data.can_use_bulk_import;

            return response.data.success;
          }).catch(error => {
            console.log(error);
            return false;
      })
    },
    clearCart: function ()  {
      this.$refs.clearCartButton.classList.add('btn-loading');

      axios.post(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/cart/clear')
          .then(response => {
            if (response.data.error) {
              console.log(response.data.error);
              return;
            }

            this.cartItems = response.data.cart;
            this.canUseBulkImport = response.data.can_use_bulk_import;

            this.$refs.clearCartButton.classList.remove('btn-loading');
            return response.data.success;
          }).catch(error => {
            console.log(error);

            this.$refs.clearCartButton.classList.remove('btn-loading');
            return false;
      })
    },
    checkout: function () {
      this.$refs.checkoutButton.classList.add('btn-loading');

      axios.post(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/cart/checkout')
          .then(response => {
            this.$refs.checkoutButton.classList.remove('btn-loading');

            if (response.data.error) {
              console.log(response.data.error);
              this.checkoutFailureCleanupAnimation(response.data.error);
              return;
            }

            this.checkoutSuccessCleanupAnimation();
            return response.data.success;
          }).catch(error => {
            console.log(error);

            this.$refs.checkoutButton.classList.remove('btn-loading');
            return false;
      })
    },
    checkoutSuccessCleanupAnimation: function () {
      this.checkoutCheckmarkDisabled = false;

      setTimeout(() => {
        this.checkoutCheckmarkDisabled = true;
        this.cartItems = {};
      }, 3000)
    },
    checkoutFailureCleanupAnimation: function (message) {
      this.errorMessage = message;

      setTimeout(() => {
        this.errorMessage = null;
      }, 5000)
    }
  },
  mounted() {
    this.getCartItems();

    this.emitter.on('add-to-cart', (evt) => {
      this.addToCart(evt.variantId);
    })

    this.emitter.on('set-cart', (evt) => {
      this.cartItems = evt.cart;
    })
  },
}
</script>

<template>
  <div class="input-icon">
    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#cart-modal">
      <IconShoppingCart />
      <span class="badge bg-red" v-if="Object.keys(cartItems).length > 0">{{ cartQuantity }}</span>
    </button>
  </div>

  <div class="modal fade" id="cart-modal" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header pe-3">
          <h5 class="modal-title">Cart</h5>

          <span>Total: ${{ cartTotal }}</span>
          <BulkImportComponent v-if="canUseBulkImport"/>
        </div>
        <div class="modal-body pt-0">
          <div v-if="Object.keys(cartItems).length > 0">
            <div class="table-responsive">
              <table class="table table-vcenter">
                <!-- <thead>
                  <tr>
                    <th>Shoe</th>
                    <th>Offer</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead> -->
                <tbody>
                  <tr v-for="item in cartItems" :key="item.id">
                    <CartItem :item="item" />

                    <td>
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <button type="button" class="btn shadow-none border-0 btn-md ml-1" @click="addToCart(item.variant_id,true, $event)">
                            <IconPlus />
                          </button>

                          {{ item.quantity }}

                          <button type="button" class="btn shadow-none border-0 btn-md" @click="removeFromCart(item.variant_id, true, $event)">
                            <IconMinus />
                          </button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button type="button" class="btn btn-danger btn-icon" @click="removeFromCart(item.variant_id, false, $event)">
                        <IconTrashX />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex mt-2" v-if="errorMessage">  <!-- failure message -->
              <p class="text-danger">
                {{ errorMessage }}
              </p>
            </div>

            <div class="d-flex mt-2 justify-content-between">
              <div class="input-icon">
                <button type="button" class="btn" ref="clearCartButton" @click="clearCart">
                  CLEAR CART
                </button>
              </div>

              <div class="input-icon">
                <button type="button" class="btn btn-dark-green btn-green" ref="checkoutButton" @click="checkout" v-if="checkoutCheckmarkDisabled === true">
                  CHECKOUT
                </button>

                <button type="button" class="btn btn-success" v-else>
                  <IconCircleCheckFilled />
                </button>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="empty">
              <div class="empty-icon">
                <IconMoodEmpty />
              </div>
              <p class="empty-title">No products in cart</p>
              <p class="empty-subtitle text-muted">
                Try adding some products to your cart.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn-dark-green {
  background-color: #28500f;
}
</style>