<script setup>
import ShoeCardComponent from "../components/product/ShoeCardComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
</script>


<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="container py-2 flex-grow-1">
      <h2 class="page-title mb-3 mt-2" v-if="shoes.length !== 0">{{ jwt !== null ? "Recently Viewed" : "Best Sellers" }}</h2>

      <div class="row row-deck row-cards">
        <div class="col-sm-3" v-for="product in shoes" :key="product.slug">
          <ShoeCardComponent
            :image_url="product.image_url"
            :title="product.title"
            @click="$router.push({ name: 'shoe', params: { slug: product.slug }})"
          />
        </div>
      </div>

      <div v-if="shoes.length === 0 && jwt !== null">
        <div class="empty">
          <div class="empty-icon">
            <IconMoodSmile />
          </div>
          <p class="empty-title">No history found.</p>
          <p class="empty-subtitle text-muted">
            You have not viewed any products yet.
          </p>
        </div>
      </div>
    </div>

    <FooterComponent />
  </div>
</template>

<script>
import { IconMoodSmile } from '@tabler/icons-vue';
import axios from 'axios';

export default {
  name: "HomeView",
  components: {
    IconMoodSmile
  },
  data() {
    return {
      shoes: [],
      jwt: null
    }
  },
  methods: {
  },
  mounted() {
    this.jwt = localStorage.getItem('jwt')

    if (localStorage.getItem('jwt') !== null) {
      axios.get(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/shoe_history/').then(response => {
        this.shoes = response.data.shoes
      }).catch(error => {
        console.log(error)
      })
    } else {
      axios.get(import.meta.env.VITE_BASE_API + import.meta.env.VITE_BASE_API_URL + '/shoe/popular_shoes').then(response => {
        this.shoes = response.data.shoes
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style>
body {
  background-color: #FFFFFF !important;
}
</style>